import "../styles/main.css";

document.addEventListener("DOMContentLoaded", function () {
  // Your code here
  onMounted();
});

const onMounted = () => {
  console.log("Hello World");
  const currentScrollPosition = window.scrollY;
  toggleMenu(document.body, currentScrollPosition);
};

const throttle = (func, limit) => {
  let lastCall = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastCall >= limit) {
      lastCall = now;
      func.apply(this, args);
    }
  };
}

const toggleMenu = (selector, posY) => {
  posY > 200
    ? selector.classList.add("scroll")
    : selector.classList.remove("scroll");
};

const handleScroll = () => {
  let scrollPosition = window.scrollY;
  toggleMenu(document.body, scrollPosition);
}

window.addEventListener("scroll",  throttle(handleScroll, 10))
